.formButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  color: #f5f5f5;
  font-family: 'BoutrosART';
  font-size: 2vw;
  border: none;
  margin-top: 1vh;
  cursor: pointer;
  margin-right: 10%;
  background-color: transparent;
  align-self: flex-end;
  transition: all 0.3s ease-out;
}

.formButton:hover {
  transform: skewY(5deg);
}

.formButton:focus {
  outline: none;
}

.formButton:disabled {
  cursor: default;
  opacity: 0.6;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .formButton {
    font-size: 3.2vw;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .formButton {
    font-size: 7vw;
    margin-right: 5%;
  }
}