@font-face {
  font-family: 'Avenir';
  font-weight: 400;
  src: url('./assets/Avenir-Book-01.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 500;
  src: url('./assets/Avenir-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Avenir';
  font-weight: 900;
  src: url('./assets/Avenir-Black-03.ttf') format('truetype');
}

@font-face {
  font-family: 'BoutrosART';
  src: url('./assets/BoutrosART-Medium.otf') format('opentype');
}

.fixedOverlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000000000;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 15vh;
}

.navMenuIcon {
  display: none;
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 6vw;
}

.logoContainer {
  position: absolute;
  width: auto;
  height: auto;
}

.firstLogo {
  position: relative;
  width: auto;
  object-fit: cover;
}

.secondLogo {
  position: absolute;
  width: auto;
  object-fit: cover;
}

.layout {
  width: 100vw;
  height: auto;
  z-index: 100;
  position: relative;
  overflow: scroll;
}

.headerImg {
  height: 103vh;
  width: auto;
  position: absolute;
  top: 0;
  right: 0;
}

.bgOne {
  z-index: 101;
}

.bgTwo {
  z-index: 102;
}

.bgThree {
  z-index: 103;
}

.mainImg {
  z-index: 104;
  filter: brightness(60%);
}

.headerContainer {
  position: relative;
}

.headerTextContainer {
  position: absolute;
  top: calc(72% + 2vh);
  left: 6vw;
  margin-top: 2vh;
}

.servicesContainer {
  width: 88vw;
  height: 110vh;
  margin-left: 6vw;
  margin-right: 6vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.firstLine {
  position: fixed;
  top: 0;
  left: 35.33304vw;
  width: 0;
  border: 1px solid rgba(151, 151, 151, 0.1);
  z-index: 10;
}

.secondLine {
  position: fixed;
  top: 0;
  left: 64.66608vw;
  width: 0;
  border: 1px solid rgba(151, 151, 151, 0.1);
  z-index: 10;
}

.featureContainer {
  width: 88vw;
  margin-right: 6vw;
  margin-left: 6vw;
  height: 120vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.featureImgContainer {
  width: 33.33%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.featureImg {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 25vw;
  height: auto;
}

.featureInfoContainer {
  width: 66.66%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.featureTitle {
  margin-bottom: 2vh;
}

.featureText {
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 2.4vw;
  color: rgba(51,51,51,0.6);
  width: 40vw;
  text-align: center;
}

.contactInfoContainer {
  width: 33.33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactEmailTitle {
  font-family: 'BoutrosART';
  font-size: 2vw;
  color: #333;
  margin-bottom: 2vh;
}

.contactEmail {
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1.2vw;
  color: #333;
  margin-bottom: 5vh;
  text-decoration: none;
  cursor: pointer;
}

.contactLinkedInTitle {
  font-family: 'BoutrosART';
  font-size: 2vw;
  color: #333;
  margin-bottom: 2vh;
  text-decoration: none;
  cursor: pointer;
}

.contactFormContainer {
  width: 66.66%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactFormInner {
  width: 80%;
  height: 55%;
  background-color: #0D0D0D;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.formLine {
  width: 80%;
  height: auto;
  margin-bottom: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.formInputOuterContainer {
  width: 48%;
  height: auto;
}

.formInputFullContainer {
  width: 80%;
  height: auto;
}

.footer {
  width: 88vw;
  height: 8vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 6vw;
  padding-left: 6vw;
  background-color: #000;
}

.copyright {
  font-family: 'Avenir';
  font-weight: 500;
  color: #f5f5f5;
  font-size: 1.1vw;
}

.animaLink {
  width: auto;
  height: 35%;
}

.animaImg {
  width: auto;
  height: 100%;
  object-fit: cover;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .fixedOverlay {
    height: 8vh;
  }

  .navMenuIcon {
    display: flex;
    margin-right: 6vw;
    z-index: 1000000000000000;
  }

  .mobileMenu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    z-index: 10000000;
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .mobileMenuItem {
    font-family: 'BoutrosART';
    font-size: 5vw;
    color: #f5f5f5;
    margin-bottom: 2vh;
  }

  .navbar {
    display: none;
  }

  .headerTextContainer {
    position: absolute;
    top: 72vh;
    left: 6vw;
    margin-top: 0;
  }

  .headerImg {
    height: 60vh;
    width: auto;
    position: absolute;
    top: 0;
    right: 0;
  }

  .servicesContainer {
    width: 88vw;
    height: 60vh;
  }

  .featureContainer {
    height: 40vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .featureText {
    font-size: 1.6vw;
    line-height: 3.2vw;
    text-align: center;
    width: 50vw;
  }

  .contactContainer {
    height: 65vh;
  }
  
  .contactEmailTitle {
    font-family: 'BoutrosART';
    font-size: 3.5vw;
    color: #333;
    margin-bottom: 3vh;
  }
  
  .contactEmail {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 1.7vw;
    color: #333;
    margin-bottom: 4vh;
    text-decoration: none;
    cursor: pointer;
  }
  
  .contactLinkedInTitle {
    font-family: 'BoutrosART';
    font-size: 3.5vw;
    color: #333;
    margin-bottom: 3vh;
    text-decoration: none;
    cursor: pointer;
  }
  
  .contactFormContainer {
    width: 66.66%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .contactFormInner {
    width: 90%;
    height: 70%;
    background-color: #0D0D0D;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .formLine {
    width: 80%;
    height: auto;
    margin-bottom: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .formInputOuterContainer {
    width: 48%;
    height: auto;
  }
  
  .formInputFullContainer {
    width: 80%;
    height: auto;
  }

  .footer {
    height: 6vh;
  }

  .copyright {
    font-size: 2vw;
  }
  
  .animaLink {
    width: auto;
    height: 40%;
  }
  
  .animaImg {
    width: auto;
    height: 100%;
    object-fit: cover;
  }
}

@media (orientation: portrait) and (max-width: 767px) {

  .fixedOverlay {
    height: 10vh;
  }

  .mobileMenu {
    padding-top: 8vh;
  }

  .mobileMenuItem {
    font-family: 'BoutrosART';
    font-size: 8vw;
    margin-bottom: 2vh;
  }

  .firstLogo {
    margin-bottom: 1.1vh;
  }

  .firstLine, .secondLine {
    display: none;
  }

  .servicesContainer {
    width: 88vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5vh;
    margin-bottom: 15vh;
  }

  .featureContainer {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .inverseFeatureContainer {
    flex-direction: column-reverse;
  }

  .featureImgContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
  }
  
  .featureImg {
    position: static;
    width: 70vw;
    height: auto;
  }
  
  .featureInfoContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    margin-bottom: 6vh;
  }

  .featureText {
    font-size: 4vw;
    line-height: 8vw;
    width: 88vw;
    text-align: center;
  }

  .contactContainer {
    width: 88vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10vh;
    margin-bottom: 10vh;
  }

  .contactInfoContainer {
    width: 100%;
  }
  
  .contactEmailTitle {
    font-family: 'BoutrosART';
    font-size: 7vw;
    color: #333;
    margin-bottom: 5vh;
  }
  
  .contactEmail {
    font-family: 'Avenir';
    font-weight: 400;
    font-size: 5.2vw;
    color: #333;
    margin-bottom: 4vh;
    text-decoration: none;
    cursor: pointer;
  }
  
  .contactLinkedInTitle {
    font-family: 'BoutrosART';
    font-size: 7vw;
    color: #333;
    margin-bottom: 7vh;
    text-decoration: none;
    cursor: pointer;
  }
  
  .contactFormContainer {
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .contactFormInner {
    width: 90%;
    height: 100%;
    background-color: #0D0D0D;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .formLine {
    width: 80%;
    height: auto;
    margin-bottom: 2vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .formInputOuterContainer {
    width: 48%;
    height: auto;
  }
  
  .formInputFullContainer {
    width: 80%;
    height: auto;
  }

  .footer {
    height: 10vh;
  }

  .copyright {
    font-size: 3vw;
  }
  
  .animaLink {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  
  .animaImg {
    width: auto;
    height: 40%;
    object-fit: cover;
  }
}

