.serviceBlock {
  height: 50vh;
  width: 33.333%;
  position: relative;
  transition: all 0.3s ease-out;
}

.serviceBlock:hover {
  height: 65vh;
}

.serviceBlockTitle {
  font-family: 'BoutrosART';
  font-size: 1.8vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  transition: all 0.3s ease-out;
}

.serviceBlock:hover .serviceBlockTitle {
  top: 50%;
  font-size: 2.4vw;
}

.serviceBlockIcon {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -40%);
  opacity: 1;
  transition: all 0.3s ease-out;
}

.serviceBlock:hover .serviceBlockIcon {
  opacity: 0;
}

.serviceBlockImg{
  height: 18vh;
  width: auto;
  object-fit: cover;
  position: absolute;
  top: 10vh;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;
  transition: all 0.5s ease-out;
}

.serviceBlock:hover .serviceBlockImg {
  opacity: 1;
}

.serviceBlockText {
  font-family: 'Avenir';
  font-weight: 400;
  font-size: 1vw;
  line-height: 2.5vw;
  text-align: center;
  width: 60%;
  position: absolute;
  bottom: 13%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;
  transition: all 0.5s ease-out;
}

.serviceBlock:hover .serviceBlockText {
  opacity: 1;
}

@media (orientation: portrait) and (max-width: 1024px) {
  .serviceBlock {
    height: 35vh;
    width: 100%;
    position: relative;
    transition: all 0.3s ease-out;
  }
  
  .serviceBlock:hover {
    height: 40vh;
  }

  .serviceBlockTitle {
    font-size: 2.8vw;
  }
  
  .serviceBlock:hover .serviceBlockTitle {
    top: 50%;
    font-size: 3.8vw;
  }

  .serviceBlockImg{
    height: 10vh;
    top: 5vh;
  }

  .serviceBlockText {
    font-size: 1.5vw;
    line-height: 3.5vw;
    width: 70%;
    bottom: 8%;
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .serviceBlock {
    height: 50vh;
    width: 100%;
    position: relative;
    transition: all 0.3s ease-out;
  }
  
  .serviceBlock:hover {
    height: 60vh;
  }

  .serviceBlockTitle {
    font-size: 7vw;
  }
  
  .serviceBlock:hover .serviceBlockTitle {
    top: 45%;
    font-size: 8.5vw;
  }

  .serviceBlockImg{
    height: 13vh;
    top: 7vh;
  }

  .serviceBlockText {
    font-size: 4.5vw;
    line-height: 9vw;
    width: 80%;
    bottom: 6%;
  }
}