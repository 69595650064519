.navbarLinkContainer {
  margin-left: 4vw;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: auto;
  height: auto;
}

.navbarLinkFirst {
  position: relative;
  top: 0;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease-out;
}

.navbarLinkSecond {
  position: absolute;
  top: 200%;
  left: 0;
  opacity: 0;
  transition: all 0.5s ease-out;
}

.navbarLinkContainer:hover .navbarLinkFirst {
  top: -100%;
  opacity: 0;
  transform: skewY(3deg);
}

.navbarLinkContainer:hover .navbarLinkSecond {
  top: 0;
  transform: skewY(3deg);
  opacity: 1;
}

.navbarLinkText {
  font-family: 'Avenir';
  font-weight: 500;
  color: #ffffff;
  font-size: 1vw;
  margin-top: 0;
  margin-bottom: 0;
}